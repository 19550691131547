import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Grid, IconButton, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const styles = theme => ({
	secondaryBar: {
		zIndex: 0,
	},
	menuButton: {
		marginLeft: -theme.spacing(1),
	},
	iconButtonAvatar: {
		padding: 4,
	},
	link: {
		color: lightColor,
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
	button: {
		borderColor: lightColor,
	},
})

function Header({ classes, onDrawerToggle, title }) {
	return (
		<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
			<Toolbar>
				<Grid container alignItems="center" spacing={8}>
					<Grid item xs>
						<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
							{title}
						</Typography>
					</Grid>
					<Grid item>
						<Tooltip title="Help">
							<IconButton color="inherit">
								<HelpIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	)
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	onDrawerToggle: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
}

export default withStyles(styles)(Header)
